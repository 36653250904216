import {
  AddInformation,
  DeleteInformation,
  GetInformationDetail,
  EditInformation
} from '@/api/gov/gov.js'

const govModule = {
  state: {
    
  },
  getters: {
    
  },
  actions: {
    // 政府资讯动态、政策指引信息添加
    addInformation({ commit, state, dispatch }, params) {
      return AddInformation(params)
    },
    // 政府资讯动态、政策指引信息删除
    deleteInformation({ commit, state, dispatch }, params) {
      return DeleteInformation(params)
    },
    // 政府资讯动态、政策指引信息详情
    getInformationDetail({ commit, state, dispatch }, params) {
      return GetInformationDetail(params)
    },
    // 政府资讯动态、政策指引信息编辑
    editInformation({ commit, state, dispatch }, params) {
      return EditInformation(params)
    }
  },
  mutations: {
    
  }
}

export default govModule
