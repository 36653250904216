<template>
    <a-locale-provider :locale="locale">
      <Header></Header>
      <router-view />
      <router-view>
        <div>
          <a-back-top />
        </div>
      </router-view>
      <Footer></Footer>
    </a-locale-provider>
</template>

<script>
  import Header from './components/Header'
  import Footer from './components/Footer'
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    data () {
        return {
            locale: zhCN,
        }
    }
  }

</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background-color: @color-fa;
}
</style>
