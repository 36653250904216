import {
  GetDemandList,
  GetSuccessList,
  GetSuccessList1,
  GetPolicyList,
  GetListLimit,
  GetNewsList,
  GetProductList,
  GetOutLine,
  GetBankSum,
  GetRateASC,
  GetBankRunDaysASC
} from '@/api/home/home.js'

const homeModule = {
  state: {

  },
  getters: {

  },
  actions: {
    // 获取融资需求列表
    async getDemandList(context, params) {
      const res = await GetDemandList(params)
      return Promise.resolve(res)
    },
    // 获取成果对接列表
    async getSuccessList(context, params) {
      const res = await GetSuccessList(params)
      return Promise.resolve(res)
    },
    // 获取成果对接列表
    async getSuccessList1(context, params) {
      const res = await GetSuccessList1(params)
      return Promise.resolve(res)
    },
    // 获取热门产品列表
    async getProductList(context, params) {
      const res = await GetProductList(params)
      return Promise.resolve(res)
    },
    // 获取政策指引列表
    async getPolicyList(context, params) {
      const res = await GetPolicyList(params)
      return Promise.resolve(res)
    },
    async GetListLimit(context, params) {
      const res = await GetListLimit(params)
      return Promise.resolve(res)
    },
    // 获取资讯动态列表
    async getNewsList(context, params) {
      const res = await GetNewsList(params)
      return Promise.resolve(res)
    },
    // 获取大数据统计
    async getOutLine(context,params) {
      const res = await GetOutLine(params)
      return Promise.resolve(res)
    },
    // 获取放款最多列表
    async getBankSum(context, params) {
      const res = await GetBankSum(params)
      return Promise.resolve(res)
    },
    // 获取利率最低列表
    getRateASC(context, params) {
      return GetRateASC(params)
    },
    // 获取效率最快列表
    getBankRunDaysASC(context, params) {
      return GetBankRunDaysASC(params)
    }
  },
  mutations: {

  }
}

export default homeModule
