import {
    RegisterPhone
} from '@/api/register/registerPhone.js'

const registerPhoneModule = {
    state: {

    },
    getters: {

    },
    actions: {
        // 注册
        getRegisterPhone({ commit, state, dispatch }, params) {
            return RegisterPhone(params)
        }
    },
    mutations: {

    }
}

export default registerPhoneModule