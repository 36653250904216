import {
  AddDemand,
  AddFiles,
  EnterpriseAuth,
  EnterpriseAuthEdit,
  AddProducts,
  GetServiceItemList,
  GetProductDetail,
  GetNewsDetail,
  GetDepBankList,
  ApplyProduct,
  DeleteDemand,
  DeleteProducts,
  EditProducts,
  GetSmartDemandList,
  EditProductsStatus,
  SetPersonInfo,
  deleteBusiness,
  reject,
  SetAuditFlag,
  GetLinkEnterProcLogDetail,
  GetLinkEnterProcOkList,
  GetBankRepaList,
  ModifyPwd, SendVarCode,
  financingStatistics,institutionFinancing,platformFinancingStatistics
} from '@/api/other/other.js'

const otherModule = {
  state: {
    productDetail: {},
    newsDetail: {}
  },
  getters: {
    // 产品详情
    productDetail (state) {
      return state.productDetail
    },
    // 新闻详情
    newsDetail (state) {
      return state.newsDetail
    }
  },
  actions: {
    // 注册
    addDemand({ commit, state, dispatch }, params) {
      return AddDemand(params)
    },
    // 上传文件
    addFiles({ commit, state, dispatch }, params) {
      return AddFiles(params)
    },
    // 企业认证
    getEnterPriseAuth({ commit, state, dispatch }, params) {
      return EnterpriseAuth(params)
    },
    // 企业修改认证
    getEnterPriseAuthEdit({ commit, state, dispatch }, params) {
      return EnterpriseAuthEdit(params)
    },

    // 手机验证码
    getVerCode({ commit, state, dispatch }, params) {
      return SendVarCode(params)
    },
    // 金融机构发布产品
    addProducts({ commit, state, dispatch }, params) {
      return AddProducts(params)
    },
    // 需求发布->服务方式列表
    getServiceItemList({ commit, state, dispatch }, params) {
      return GetServiceItemList(params)
    },
    // 获取金融产品详情
    async getProductDetail({ commit, state, dispatch }, params) {
      const res = await GetProductDetail(params)
      commit('SET_DETAILS', res.pd)
    },
    // 获取新闻详情
    async getNewsDetail({ commit, state, dispatch }, params) {
      const res = await GetNewsDetail(params)
      commit('SET_NEWS_DETAILS', res.pd)
    },
    // 获取金融机构列表
    getDepBankList({ commit, state, dispatch }, params) {
      return GetDepBankList(params)
    },
    // 企业对接金融产品
    applyProduct({ commit, state, dispatch }, params) {
      return ApplyProduct(params)
    },
    // 删除企业需求
    deleteDemand ({ commit, state, dispatch }, params) {
      return DeleteDemand(params)
    },
    // 删除金融机构发布的产品
    deleteProducts ({ commit, state, dispatch }, params) {
      return DeleteProducts(params)
    },
    // 修改金融机构发布的产品
    editProducts ({ commit, state, dispatch }, params) {
      return EditProducts(params)
    },
    // 获取智能产品匹配列表
    getSmartDemandList ({ commit, state, dispatch }, params) {
      return GetSmartDemandList(params)
    },
    // 我的服务-》修改金融机构对接需求状态
    editProductsStatus ({ commit, state, dispatch }, params) {
      return EditProductsStatus(params)
    },
    // 金融机构设置业务经理及联系方式
    setPersonInfo ({ commit, state, dispatch }, params) {
      return SetPersonInfo(params)
    },
    //删除
    deleteBusiness({commit, state, dispatch}, params) {
      return deleteBusiness(params)
    },
    // 驳回报告
    reject ({ commit, state, dispatch }, params) {
      return reject(params)
    },
    // 修改对接状态
    setAuditFlag ({ commit, state, dispatch }, params) {
      return SetAuditFlag(params)
    },
    // 获取对接审批详情
    getLinkEnterProcLogDetail ({ commit, state, dispatch }, params) {
      return GetLinkEnterProcLogDetail(params)
    },
    // 获取成功对接列表
    getLinkEnterProcOkList ({ commit, state, dispatch }, params) {
      return GetLinkEnterProcOkList(params)
    },
    // 获取所有金融机构列表
    getBankRepaList ({ commit, state, dispatch }, params) {
      return GetBankRepaList(params)
    },
    // 修改密码
    modifyPwd ({ commit, state, dispatch }, params) {
      return ModifyPwd(params)
    },
    //信用中国 -- 地方回传
    financingStatistics({ commit, state, dispatch }, params){
      return financingStatistics(params)
    },
    //信用中国 -- 金融机构回传
    institutionFinancing({ commit, state, dispatch }, params){
      return institutionFinancing(params)
    },
    //信用中国 -- 平台回传
    platformFinancingStatistics({ commit, state, dispatch }, params){
      return platformFinancingStatistics(params)
    }
  },
  mutations: {
    // 设置金融产品详情
    SET_DETAILS (state, res) {
      if (res.LOGO_ADDR) {
        if (res.LOGO_ADDR.split('.')[1] != 'jpg' && res.LOGO_ADDR.split('.')[1] != 'jpeg' && res.LOGO_ADDR.split('.')[1] != 'png') {
          res.LOGO_ADDR = ''
        }
      } else {
        res.LOGO_ADDR = ''
      }
      state.productDetail = res
    },
    // 设置新闻详情
    SET_NEWS_DETAILS (state, res) {
      state.newsDetail = res
    }
  }
}

export default otherModule
