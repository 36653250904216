import loginModule from './login/login.js'
import homeModule from './home/home.js'
import registerModule from './register/register.js'
import otherModule from './other/other.js'
import orgModule from './org/org.js'
import govModule from './gov/gov.js'
import msgModule from './msg/msg.js'
import registerPhoneModule from "./register/registerPhone.js";
const modules = {
  loginModule,
  homeModule,
  registerModule,
  otherModule,
  orgModule,
  govModule,
  msgModule,
  registerPhoneModule
}

export default modules
