import {
  GetPersonList,
  AddPersonList,
  GetDiagrepbaseList,
  AddCaiShui,
  GetCaiShuiList,
  GetCaiShuiDetail,
  GenDiagnosisReport,
  DeletePerson,
  DeleteCaiShui,
  GetZCFZList,
  SaveZCFZ,
  GetLYList,
  SaveLY,
  GetXJLLList,
  SaveXJLL,
  GetCSCSList,
  SaveCSCS,
  GetXXFPList,
  AddXXFPItem,
  DeleteXXFPItem,
  GetXXFPDetail,
  EditXXFPItem,
  GetJXFPList,
  DeleteJXFPItem,
  GetJXFPDetail,
  EditJXFPItem,
  GetNSPJList,
  AddNSPJItem,
  DeleteNSPJItem,
  GetNSPJDetail,
  EditNSPJItem,
  GetGSSJList,
  DeleteGSSJItem,
  GetGSSJDetail,
  AddGSSJItem,
  EditGSSJItem,
  GetNHSJList,
  DeleteNHSJItem,
  AddNHSJItem,
  GetNHSJDetail,
  EditNHSJItem,
  GetYLBXList,
  DeleteYLBXItem,
  AddYLBXItem,
  GetYLBXDetail,
  EditYLBXItem,
  GetOtherList,
  DeleteOtherItem,
  GetOtherDetail,
  EditOtherItem,
  AddOtherItem,
  GetDiagrepbaseDetail,
  GetJudicialList,
  GetAdministrativepenaltyList,
  GetClearinginfoList,
  GetAbnormitydirectoryList,
  GetChattelmortgageList,
  EditCaiShui,
  AddDeepReport,
  GetDeepReportList,
  GetDeepReportEdit, GetRiskPersiongageList,
  GetKnowledgeData,DownloadImpByChart,
  CreateNative,QueryPayStatus

} from '@/api/org/org.js'

const orgModule = {
  state: {
    isEdit: false,
    y: new Date().getFullYear() - 1,
    addXXStatus: false,
    sbItem: ''
  },
  getters: {
    isEdit (state) {
      return state.isEdit
    },
    y (state) {
      return state.y
    },
    addXXStatus (state) {
      return state.addXXStatus
    },
    sbItem (state) {
      return state.sbItem
    }
  },
  actions: {
    // 企业人员信息-查询
    getPersonList ({ commit, state, dispatch }, params) {
      return GetPersonList(params)
    },
    // 企业人员信息-添加
    addPersonList ({ commit, state, dispatch }, params) {
      return AddPersonList(params)
    },
    // 获取企业诊断列表
    getDiagrepbaseList ({ commit, state, dispatch }, params) {
      return GetDiagrepbaseList(params)
    },
    // 获取企业诊断详情
    getDiagrepbaseDetail ({ commit, state, dispatch }, params) {
      return GetDiagrepbaseDetail(params)
    },
    // 财税信息-添加
    addCaiShui ({ commit, state, dispatch }, params) {
      return AddCaiShui(params)
    },
    // 财税信息-列表
    getCaiShuiList ({ commit, state, dispatch }, params) {
      return GetCaiShuiList(params)
    },
    // 财税信息-详情
    getCaiShuiDetail ({ commit, state, dispatch }, params) {
      return GetCaiShuiDetail(params)
    },
    // 生成初始诊断报告
    genDiagnosisReport ({ commit, state, dispatch }, params) {
      return GenDiagnosisReport(params)
    },
    // 删除企业人员
    deletePerson ({ commit, state, dispatch }, params) {
      return DeletePerson(params)
    },
    // 删除财税信息
    deleteCaiShui ({ commit, state, dispatch }, params) {
      return DeleteCaiShui(params)
    },
    // 获取资产负债列表
    getZCFZList ({ commit, state, dispatch }, params) {
      return GetZCFZList(params)
    },
    // 保存资产负债
    saveZCFZ ({ commit, state, dispatch }, params) {
      return SaveZCFZ(params)
    },
    // 获取利润列表
    getLYList ({ commit, state, dispatch }, params) {
      return GetLYList(params)
    },
    // 保存利润列表
    saveLY ({ commit, state, dispatch }, params) {
      return SaveLY(params)
    },
    // 获取现金流量表
    getXJLLList ({ commit, state, dispatch }, params) {
      return GetXJLLList(params)
    },
    // 保存现金流量表
    saveXJLL ({ commit, state, dispatch }, params) {
      return SaveXJLL(params)
    },
    // 获取财税参数表
    getCSCSList ({ commit, state, dispatch }, params) {
      return GetCSCSList(params)
    },
    // 保存财税参数表
    saveCSCS ({ commit, state, dispatch }, params) {
      return SaveCSCS(params)
    },
    // 获取经营数据-销项发票
    getXXFPList ({ commit, state, dispatch }, params) {
      return GetXXFPList(params)
    },
    // 添加单条经营数据-销项发票
    addXXFPItem ({ commit, state, dispatch }, params) {
      return AddXXFPItem(params)
    },
    // 删除单条经营数据-销项发票
    deleteXXFPItem ({ commit, state, dispatch }, params) {
      return DeleteXXFPItem(params)
    },
    // 单条经营数据详情-销项发票
    getXXFPDetail ({ commit, state, dispatch }, params) {
      return GetXXFPDetail(params)
    },
    // 编辑经营数据详情-销项发票
    editXXFPItem ({ commit, state, dispatch }, params) {
      return EditXXFPItem(params)
    },
    // 获取经营数据列表-进项发票
    getJXFPList ({ commit, state, dispatch }, params) {
      return GetJXFPList(params)
    },
    // 删除单条数据-进项发票
    deleteJXFPItem ({ commit, state, dispatch }, params) {
      return DeleteJXFPItem(params)
    },
    // 单条进项发票数据详情
    getJXFPDetail ({ commit, state, dispatch }, params) {
      return GetJXFPDetail(params)
    },
    // 编辑进项发票数据详情
    editJXFPItem ({ commit, state, dispatch }, params) {
      return EditJXFPItem(params)
    },
    // 获取纳税评级列表
    getNSPJList ({ commit, state, dispatch }, params) {
      return GetNSPJList(params)
    },
    // 添加纳税评级单条
    addNSPJItem ({ commit, state, dispatch }, params) {
      return AddNSPJItem(params)
    },
    // 删除纳税评级单条
    deleteNSPJItem ({ commit, state, dispatch }, params) {
      return DeleteNSPJItem(params)
    },
    // 获取纳税评级详情
    getNSPJDetail ({ commit, state, dispatch }, params) {
      return GetNSPJDetail(params)
    },
    // 修改纳税评级单条
    editNSPJItem ({ commit, state, dispatch }, params) {
      return EditNSPJItem(params)
    },
    // 获取个税数据列表
    getGSSJList ({ commit, state, dispatch }, params) {
      return GetGSSJList(params)
    },
    // 获取个税数据列表
    deleteGSSJItem ({ commit, state, dispatch }, params) {
      return DeleteGSSJItem(params)
    },
    // 获取个税数据详情
    getGSSJDetail ({ commit, state, dispatch }, params) {
      return GetGSSJDetail(params)
    },
    // 添加单条个税数据
    addGSSJItem ({ commit, state, dispatch }, params) {
      return AddGSSJItem(params)
    },
    // 编辑单条个税数据
    editGSSJItem ({ commit, state, dispatch }, params) {
      return EditGSSJItem(params)
    },
    // 获取电费数据列表(水费列表、燃气费列表)
    getNHSJList ({ commit, state, dispatch }, params) {
      return GetNHSJList(params)
    },
    // 删除电费数据单条(水费列表、燃气费列表)
    deleteNHSJItem ({ commit, state, dispatch }, params) {
      return DeleteNHSJItem(params)
    },
    // 添加电费数据单条(水费列表、燃气费列表)
    addNHSJItem ({ commit, state, dispatch }, params) {
      return AddNHSJItem(params)
    },
    // 电费数据(水费列表、燃气费列表)详情
    getNHSJDetail ({ commit, state, dispatch }, params) {
      return GetNHSJDetail(params)
    },
    // 修改电费数据(水费列表、燃气费列表)单条
    editNHSJItem ({ commit, state, dispatch }, params) {
      return EditNHSJItem(params)
    },
    // 获取养老保险数据列表
    getYLBXList ({ commit, state, dispatch }, params) {
      return GetYLBXList(params)
    },
    // 删除养老保险数据单条
    deleteYLBXItem ({ commit, state, dispatch }, params) {
      return DeleteYLBXItem(params)
    },
    // 新增养老保险数据单条
    addYLBXItem ({ commit, state, dispatch }, params) {
      return AddYLBXItem(params)
    },
    // 获取养老保险数据详情
    getYLBXDetail ({ commit, state, dispatch }, params) {
      return GetYLBXDetail(params)
    },
    // 修改养老保险数据单条
    editYLBXItem ({ commit, state, dispatch }, params) {
      return EditYLBXItem(params)
    },
    // 获取医疗保险数据(工伤险、生育险、住房公积金)列表
    getOtherList ({ commit, state, dispatch }, params) {
      return GetOtherList(params)
    },
    // 删除医疗保险数据(工伤险、生育险、住房公积金)单条
    deleteOtherItem ({ commit, state, dispatch }, params) {
      return DeleteOtherItem(params)
    },
    // 获取医疗保险数据(工伤险、生育险、住房公积金)详情
    getOtherDetail ({ commit, state, dispatch }, params) {
      return GetOtherDetail(params)
    },
    // 修改医疗保险数据(工伤险、生育险、住房公积金)单条
    editOtherItem ({ commit, state, dispatch }, params) {
      return EditOtherItem(params)
    },
    // 添加医疗保险数据(工伤险、生育险、住房公积金)单条
    addOtherItem ({ commit, state, dispatch }, params) {
      return AddOtherItem(params)
    },
    // 企业司法信息
    getJudicialList ({ commit, state, dispatch }, params) {
      return GetJudicialList(params)
    },
    // 企业行政处罚
    getAdministrativepenaltyList ({ commit, state, dispatch }, params) {
      return GetAdministrativepenaltyList(params)
    },
    // 企业清算信息
    getClearinginfoList ({ commit, state, dispatch }, params) {
      return GetClearinginfoList(params)
    },
    // 企业经营异常名录
    getAbnormitydirectoryList ({ commit, state, dispatch }, params) {
      return GetAbnormitydirectoryList(params)
    },
    // 企业动产抵押
    getChattelmortgageList ({ commit, state, dispatch }, params) {
      return GetChattelmortgageList(params)
    },
   //信用信息查询
    getRiskPersiongageList({ commit, state, dispatch }, params) {
      return GetRiskPersiongageList(params)
    },
    // 财税信息-编辑
    editCaiShui ({ commit, state, dispatch }, params) {
      return EditCaiShui(params)
    },
    // 添加一条深度报告
    addDeepReport ({ commit, state, dispatch }, params) {
      return AddDeepReport(params)
    },
    // 深度报告列表
    getDeepReportList ({ commit, state, dispatch }, params) {
      return GetDeepReportList(params)
    },
    //生成报告
    GetDeepReportEdit ({ commit, state, dispatch }, params) {
      return GetDeepReportEdit(params)
    },
    //生成知识图谱
    getKnowledgeData({ commit, state, dispatch }, params){
      return GetKnowledgeData(params)
    },
    //保存图片
    DownloadImpByChart({ commit, state, dispatch }, params){
      return DownloadImpByChart(params)
    },
    //微信支付 统一预下单接口
    createNative({ commit, state, dispatch }, params){
      return CreateNative(params)
    },
    //查询微信订单交易状态
    QueryPayStatus({ commit, state, dispatch }, params){
      return QueryPayStatus(params)
    },
  },
  mutations: {
    SET_ISEDIT (state, res) {
      state.isEdit = res
    },
    SET_YEAR (state, res) {
      state.y = res
    },
    SET_XXSTATUS (state, res) {
      state.addXXStatus = res
    },
    SET_SBITEM (state, res) {
      state.sbItem = res
    }
  }
}

export default orgModule
