import Axios from '../axios'
import BaseUrl from '../base'

// 企业需求录入
export function AddDemand(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisedemand/add',
    params: data
  })
}

// 企业附件上传
export function AddFiles(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisefiles/add',
    params: data
  })
}

// 企业认证
export function EnterpriseAuth(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/add',
    params: data
  })
}
// 是否上传四张图片
export function uploadImg(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs + '/enterprise/searchSwitch',
    params: data
  })
}
export function EnterpriseAuthEdit(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/edit',
    params: data
  })
}

// 手机验证码
export function SendVarCode(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/sendVarCode',
    params: data
  })
}

// 金融机构发布产品
export function AddProducts(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/financialproducts/add',
    params: data,
    type:Blob
  })
}

// 需求发布->服务方式列表
export function GetServiceItemList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/dictionaries/getLevels',
    params: data
  })
}

// 获取金融产品详情
export function GetProductDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/financialproducts/goEdit',
    params: data
  })
}

// 获取新闻详情
export function GetNewsDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/goEdit',
    params: data
  })
}

// 获取金融机构列表
export function GetDepBankList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/depbank/list',
    params: data
  })
}

// 企业申请产品
export function ApplyProduct(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/add',
    params: data
  })
}

// 删除企业需求
export function DeleteDemand(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisedemand/delete',
    params: data
  })
}

// 删除金融机构发布的产品
export function DeleteProducts(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/financialproducts/delete',
    params: data
  })
}

// 修改金融机构发布的产品
export function EditProducts(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/financialproducts/edit',
    params: data
  })
}

// 获取智能产品匹配列表
export function GetSmartDemandList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisedemand/list_demand',
    params: data
  })
}

// 我的服务-》修改金融机构对接需求状态
export function EditProductsStatus(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/setConfirmYN',
    params: data
  })
}

// 金融机构设置业务经理及联系方式
export function SetPersonInfo(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/setPersonInfo',
    params: data
  })
}

//业务处理删除
export function deleteBusiness(data) {
  return Axios({
    method:'post',
    url: BaseUrl.cs + '/linkenterproc/delete',
    params:data
  })
}


// 驳回报告
export function reject(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/reject',
    params: data
  })
}

// 修改对接状态
export function SetAuditFlag(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/setAuditFlag',
    params: data
  })
}

// 获取对接审批详情
export function GetLinkEnterProcLogDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproclog/list',
    params: data
  })
}

// 获取成功对接列表
export function GetLinkEnterProcOkList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/list_hide',
    params: data
  })
}

// 获取所有金融机构列表
export function GetBankRepaList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/list_banktotal1',
    params: data
  })
}

// 修改密码
export function ModifyPwd(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/user/modifyPwd',
    params: data
  })

}
export function financingStatistics(data) {
  return Axios({
  method: 'post',
      url: BaseUrl.cs + '/backInterface/financingStatistics',
      params: data
  })
}


export function institutionFinancing(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/backInterface/institutionFinancing',
    params: data
  })
}

export function platformFinancingStatistics(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/backInterface/platformFinancingStatistics',
    params: data
  })
}
//附件提交 /enterprise/editFileAddr
export function editFileAddr(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/editFileAddr',
    params: data,
    type:Blob
  })
}
// 认证
export function auth(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/auth',
    params: data
  })
}
///dictionaries/getLevels
export function getLevels(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/dictionaries/getLevels',
    params: data
  })
}
// 企业详情
export function enterprise(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs + '/enterprisePortrait/tyc',
    params: data
  })
}