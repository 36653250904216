import Axios from '../axios'
import BaseUrl from '../base'

// 企业人员信息-查询
export function GetPersonList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterperson/list',
    params: data
  })
}

// 企业人员信息-添加
export function AddPersonList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterperson/add',
    params: data
  })
}

// 企业诊断列表
export function GetDiagrepbaseList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/diagrepbase/list',
    params: data
  })
}

// 企业诊断详情
export function GetDiagrepbaseDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/diagrepbase/goEdit',
    params: data
  })
}

// 财税信息-添加
export function AddCaiShui(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/add',
    params: data
  })
}

// 财税信息-查询列表
export function GetCaiShuiList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/list',
    params: data
  })
}

// 财税信息-详情
export function GetCaiShuiDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/goEdit',
    params: data
  })
}

// 财税信息-编辑
export function EditCaiShui(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/edit',
    params: data
  })
}

// 生成初始诊断报告
export function GenDiagnosisReport(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/goRep',
    params: data
  })
}

// 删除企业人员
export function DeletePerson(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterperson/delete',
    params: data
  })
}

// 删除财税信息
export function DeleteCaiShui(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entercaishui/delete',
    params: data
  })
}

// 获取资产负债列表
export function GetZCFZList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterzichanfuzhai/list',
    params: data
  })
}

// 保存资产负债
export function SaveZCFZ(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterzichanfuzhai/saveAll',
    params: data
  })
}

// 获取利润列表
export function GetLYList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterlirunbiao/list',
    params: data
  })
}

// 保存利润列表
export function SaveLY(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterlirunbiao/saveAll',
    params: data
  })
}

// 获取现金流量表
export function GetXJLLList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterxianjinliu/list',
    params: data
  })
}

// 保存现金流量表
export function SaveXJLL(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterxianjinliu/saveAll',
    params: data
  })
}

// 获取财税参数表
export function GetCSCSList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entershoudongtc/list',
    params: data
  })
}

// 保存财税参数表
export function SaveCSCS(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/entershoudongtc/saveAll',
    params: data
  })
}

// 获取经营数据-销项发票
export function GetXXFPList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_xiaoxiang/list',
    params: data
  })
}

// 添加单条经营数据-销项发票
export function AddXXFPItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_xiaoxiang/add',
    params: data
  })
}

// 删除单条经营数据-销项发票
export function DeleteXXFPItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_xiaoxiang/delete',
    params: data
  })
}

// 单条经营数据详情-销项发票
export function GetXXFPDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_xiaoxiang/goEdit',
    params: data
  })
}

// 编辑经营数据详情-销项发票
export function EditXXFPItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_xiaoxiang/edit',
    params: data
  })
}

// 获取经营数据列表-进项发票
export function GetJXFPList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_jinxiang/list',
    params: data
  })
}

// 删除单条数据-进项发票
export function DeleteJXFPItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_jinxiang/delete',
    params: data
  })
}

// 单条进项发票数据详情
export function GetJXFPDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_jinxiang/goEdit',
    params: data
  })
}

// 编辑进项发票数据详情
export function EditJXFPItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_jinxiang/edit',
    params: data
  })
}

// 获取纳税评级列表
export function GetNSPJList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_nspj/list',
    params: data
  })
}

// 添加纳税评级单条
export function AddNSPJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_nspj/add',
    params: data
  })
}

// 删除纳税评级单条
export function DeleteNSPJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_nspj/delete',
    params: data
  })
}

// 获取纳税评级详情
export function GetNSPJDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_nspj/goEdit',
    params: data
  })
}

// 修改纳税评级单条
export function EditNSPJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/jydata_nspj/edit',
    params: data
  })
}

// 获取个税数据列表
export function GetGSSJList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/individual/list',
    params: data
  })
}

// 获取个税数据列表
export function DeleteGSSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/individual/delete',
    params: data
  })
}

// 获取个税数据详情
export function GetGSSJDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/individual/goEdit',
    params: data
  })
}

// 添加单条个税数据
export function AddGSSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/individual/add',
    params: data
  })
}

// 编辑单条个税数据
export function EditGSSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/individual/edit',
    params: data
  })
}

// 获取电费数据列表(水费列表、燃气费列表)
export function GetNHSJList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/nenghao_data/list',
    params: data
  })
}

// 删除电费数据单条(水费列表、燃气费列表)
export function DeleteNHSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/nenghao_data/delete',
    params: data
  })
}

// 添加电费数据单条(水费列表、燃气费列表)
export function AddNHSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/nenghao_data/add',
    params: data
  })
}

// 电费数据(水费列表、燃气费列表)详情
export function GetNHSJDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/nenghao_data/goEdit',
    params: data
  })
}

// 修改电费数据(水费列表、燃气费列表)单条
export function EditNHSJItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/nenghao_data/edit',
    params: data
  })
}

// 获取养老保险数据列表
export function GetYLBXList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_yanglao/list',
    params: data
  })
}

// 删除养老保险数据单条
export function DeleteYLBXItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_yanglao/delete',
    params: data
  })
}

// 新增养老保险数据单条
export function AddYLBXItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_yanglao/add',
    params: data
  })
}

// 获取养老保险数据详情
export function GetYLBXDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_yanglao/goEdit',
    params: data
  })
}

// 修改养老保险数据单条
export function EditYLBXItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_yanglao/edit',
    params: data
  })
}

// 获取医疗保险数据(工伤险、生育险、住房公积金)列表
export function GetOtherList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_others/list',
    params: data
  })
}

// 删除医疗保险数据(工伤险、生育险、住房公积金)单条
export function DeleteOtherItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_others/delete',
    params: data
  })
}

// 获取医疗保险数据(工伤险、生育险、住房公积金)详情
export function GetOtherDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_others/goEdit',
    params: data
  })
}

// 修改医疗保险数据(工伤险、生育险、住房公积金)单条
export function EditOtherItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_others/edit',
    params: data
  })
}

// 添加医疗保险数据(工伤险、生育险、住房公积金)单条
export function AddOtherItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/shebaodata_others/add',
    params: data
  })
}

// 企业司法信息
export function GetJudicialList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/judicialdata/listAll',
    params: data
  })
}

// 企业行政处罚
export function GetAdministrativepenaltyList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/administrativepenalty/listAll',
    params: data
  })
}

// 企业清算信息
export function GetClearinginfoList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/clearinginfo/listAll',
    params: data
  })
}

// 企业经营异常名录
export function GetAbnormitydirectoryList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/abnormitydirectory/listAll',
    params: data
  })
}

// 企业动产抵押
export function GetChattelmortgageList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/chattelmortgage/listAll',
    params: data
  })
}

// 信用信息查询
export function GetRiskPersiongageList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/judicialdata/riskListAll',
    params: data
  })
}

// 添加一条深度报告
export function AddDeepReport(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/add',
    params: data
  })
}

// 深度报告列表
export function GetDeepReportList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/list',
    params: data
  })
}
//生成报告
  export function GetDeepReportEdit(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/edit',
    params: data
  })
}
//生成知识图谱
export function GetKnowledgeData(data){
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/getKnowledgeGraphDate',
    params: data
  })
}

//保存知识图谱图片
export function DownloadImpByChart(data) {
  var formData = new FormData();
  formData.append('enterpriseId',data.enterpriseId);
  formData.append('picInfo',data.picInfo);
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterdepthreport/saveKnowledgeGraph',
    data: formData,
  })
}

//微信支付 统一预下单接口
export function CreateNative(data){
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/WeiXinPayController/createNative',
    params: data
  })
}

//查询微信订单交易状态
export function QueryPayStatus(data){
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/WeiXinPayController/QueryPayStatus',
    params: data
  })
}
