import Axios from '../axios'
import BaseUrl from '../base'

// 注册
export function Register(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/admin/reg',
    params: data
  })
}
