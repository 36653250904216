import {
  Register
} from '@/api/register/register.js'

const registerModule = {
  state: {
    
  },
  getters: {
    
  },
  actions: {
    // 注册
    getRegister({ commit, state, dispatch }, params) {
      return Register(params)
    }
  },
  mutations: {
    
  }
}

export default registerModule
