/**
 * 接口域名的管理
 */
const base = {
   // cs: 'http://localhost:8051/crpt'
   //  cs: 'http://120.46.160.20:80/crpt' //服务器地址
    cs: 'https://www.chanrongfuwu.com/crpt' //服务器地址
   //  cs: 'http://114.116.202.28:10000/crpt'  //均信测试环境
}

export default base
