import Axios from '../axios'
import BaseUrl from '../base'

// 政府资讯动态、政策指引信息添加
export function AddInformation(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/add',
    params: data
  })
}

// 政府资讯动态、政策指引信息删除
export function DeleteInformation(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/delete',
    params: data
  })
}

// 政府资讯动态、政策指引信息详情
export function GetInformationDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/goEdit',
    params: data
  })
}

// 编辑政府资讯动态、政策指引信息
export function EditInformation(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/edit',
    params: data
  })
}
