import {
  GetSysMessageList,
  DeleteSysMessageItem,
  GetSysMessageDetail
} from '@/api/msg/msg.js'

const msgModule = {
  state: {
    
  },
  getters: {
    
  },
  actions: {
    // 获取消息信息列表
    getSysMessageList({ commit, state, dispatch }, params) {
      return GetSysMessageList(params)
    },
    // 删除消息信息单条
    deleteSysMessageItem({ commit, state, dispatch }, params) {
      return DeleteSysMessageItem(params)
    },
    // 获取消息信息详情
    getSysMessageDetail({ commit, state, dispatch }, params) {
      return GetSysMessageDetail(params)
    }
  },
  mutations: {
    
  }
}

export default msgModule
