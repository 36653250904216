import Axios from '../axios'
import BaseUrl from '../base'

// 获取消息信息列表
export function GetSysMessageList(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/sysmessage/list',
    params: data
  })
}

// 删除消息信息单条
export function DeleteSysMessageItem(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/sysmessage/delete',
    params: data
  })
}

// 获取消息信息详情
export function GetSysMessageDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/sysmessage/goEdit',
    params: data
  })
}
