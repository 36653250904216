import Axios from '../axios'
import BaseUrl from '../base'

// 融资需求列表
export function GetDemandList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisedemand/list_hide',
    params: data
  })
}

// 成果对接列表
export function GetSuccessList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/list_hide',
    params: data
  })
}
// 成果对接列表
export function GetSuccessList1 (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/list',
    params: data
  })
}

// 政策指引列表
export function GetPolicyList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/policylist',
    params: data
  })
}
//不知道是啥 enterprisedemand/listlimit
export function GetListLimit (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs+'/enterprisedemand/listlimit',
    params: data
  })
}
// 咨询动态列表
export function GetNewsList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/newslist',
    params: data
  })
}

// 热门产品列表
export function GetProductList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/financialproducts/list',
    params: data
  })
}

// 大数据统计
export function GetOutLine (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/admin/getOutline',
    params: data
  })
}

// 放款最多列表
export function GetBankSum (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/bankSum',
    params: data
  })
}

// 利率最低列表
export function GetRateASC (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/bankInterRateASC',
    params: data
  })
}

// 效率最快列表
export function GetBankRunDaysASC (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/linkenterproc/bankRunDaysASC',
    params: data
  })
}
//轮播图
export function informationList (data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/informaton/list?showCount=3&currentPage=1',
    params: data
  })
}
