import Axios from '../axios'
import BaseUrl from '../base'

// 手机号注册
export function RegisterPhone(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/admin/phone',
        params: data
    })
}
