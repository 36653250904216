<template>
    <div class="header-box">
        <div class="main-box">
           <div class="main-box-logo">
<!--             :src="logot"-->
               <a href="https://xyd.creditchina.gov.cn/"><img style="width: 390px" src="../assets/images/home/logot.png"
                       alt="智慧金融综合服务平台"></a>
           </div>
           <div class="main-box-nav">
                <router-link :to="{name:item.routes}" v-for="(item,index) in navlist" :key="index">
                    {{item.title}}
                </router-link>
           </div>
        </div>
    </div>
</template>

<script>
    import {goLogoPic} from '@/api/login/login'
    export default {
        name:"Header",
        data() {
            return {
                logot:require('../assets/images/home/logot.png'),
                navlist: [
                    {
                        title:"首页",
                        routes:"Home"
                    },
                    {
                        title:"金融产品",
                        routes:"FinancialProducts"
                    },
                    {
                        title:"政策资讯",
                        routes:"PolicyInformation"
                    },
                    {
                        title:"金融机构",
                        routes:"FinancialInstitutions"
                    },
                    {
                        title:"成果展示",
                        routes:"ResultsShow"
                    },
                    {
                        title:"贷后监管",
                        routes:"Supervision"
                    },
                    {
                        title:"关于平台",
                        routes:"ContactUs"
                    },
                ]
            }
        },
        created() {
            goLogoPic({tm:new Date().getTime()}).then(res=>{
                if(res.result == 'success'){
                    this.logot = this.$baseUrl+'/'+res.varList[0].FILEPATH
                    this.logob = this.$baseUrl+'/'+res.varList[1].FILEPATH
                }
            })
        }
    }
</script>

<style lang="less" scoped>
.header-box{
    background-color: @color-ff;
   .main-box{
        width: @main-width-base;
        margin:0 auto;
        height: 100px;
        display: flex;
        justify-content: space-between;
        .main-box-logo{
            width: 360px;
            display: flex;
            align-items: center;
            margin-left: 30px;
        }
        .main-box-nav{
            width: 840px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            a{
                width: 110px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                color: #999999;
                font-size: @font-lg;
                border-radius: 40px;
            }
            a:hover{
                color: #ffffff;
                background:#457dfe;
            }
            .router-link-active{
                color: #ffffff;
                background:#457dfe;
            }
        }
   }
}
</style>
