<template>
    <div>
        <div class="module-title-box">
            <div><img src="../assets/images/home/left.png" alt=""></div>
            <div>
                <p>{{title}}</p>
                <span>{{stitle}}</span>
            </div>
            <div><img src="../assets/images/home/right.png" alt=""></div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'ModuleTitle',
         props: {
            title: String,
            stitle:String
        },
        data() {
            return {
                bg: {
                    backgroundImage: "url(" + require("../assets/images/module-title.png") + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                }
            }
        }

    }
</script>

<style lang="less" scoped>
.module-title-box{
    margin: 0 auto;
    width: 550px;
    height: 56px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    p{
        font-size: 24px;
        width: 150px;
        margin:0 auto;
        color: @color-base;
        height: 32px;
        line-height: 32px;
        letter-spacing: 2px;
    }
    span{
        color: #999999;
        display: block;
        width: 150px;
        margin:0 auto;
        font-size: @font-sm;
        letter-spacing: 1px;
    }
}
</style>
