import axios from 'axios'
/* http request
 **请求拦截器
 **在发送请求之前进行的一系列处理,根据项目要求自行配置
 **例如:loading
 */

axios.interceptors.request.use(
  config => {
    config.timeout = 60 * 1000 //请求响应时间
    // config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 400:
      console.log('错误请求')
      break;
    case 401:
      console.log('未授权,请重新登录')
      break;
    case 403:
      console.log('拒绝访问,登录过期，请重新登录')
      break;
    case 404:
      console.log('请求错误,未找到该资源')
      break;
    case 405:
      console.log('请求方法未允许')
      break;
    case 408:
      console.log('请求超时')
      break;
    case 500:
      console.log('服务器端出错')
      break;
    case 501:
      console.log('网络未实现')
      break;
    case 502:
      console.log('网络错误')
      break;
    case 503:
      console.log('服务不可用')
      break;
    case 504:
      console.log('网络超时')
      break;
    case 505:
      console.log('http版本不支持该请求')
      break;
    default:
      console.log(other)
  }
}
/* response
 **响应拦截器
 **允许在数据返回客户端前,修改响应的数据
 **返回体中数据：response.data,如果需要全部,则 return response 即可
 */
axios.interceptors.response.use(
  // 请求成功
  res => res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data),
  // 请求失败
  error => {
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit('changeNetwork', false);
    }
})

export default axios
