import {
  reqLogin,
  GetUserInfo,
  GetOrgDetail
} from '@/api/login/login.js'

import {
  GetOutLine
} from '@/api/home/home.js'

const loginModule = {
  state: {
    userName: '',
    isLogin: false,
    userId: '',
    loginType: '',
    orgId: '',
    orgName: '',
    isAuth: '',
    regType: '',
    orgType: '',
    xcxName:"",
    isUploadImg:''
  },
  getters: {
    isUploadImg(state){
      state.isUploadImg = localStorage.getItem('isUploadImg')
      return state.isUploadImg
    },
    userName (state) {
      if (state.userName === '' || state.userName === null) {
        state.userName = localStorage.getItem('userName')
      }
      return state.userName
    },
    isLogin (state) {
      if (!state.isLogin) {
        state.isLogin = localStorage.getItem('isLogin')
      }
      return state.isLogin
    },
    userId (state) {
      if (state.userId === '' || state.userId === null) {
        state.userId = localStorage.getItem('userId')
      }
      return state.userId
    },
    xcxName (state) {
      if (state.xcxName === '' || state.xcxName === null) {
        state.xcxName = localStorage.getItem('xcxName')
      }
      return state.xcxName
    },
    loginType (state) {
      if (state.loginType === '' || state.loginType === null) {
        state.loginType = localStorage.getItem('loginType')
      }
      return state.loginType
    },
    orgId (state) {
      if (state.orgId === '' || state.orgId === null) {
        state.orgId = localStorage.getItem('orgId')
      }
      return state.orgId
    },
    orgName (state) {
      if (state.orgName === '' || state.orgName === null) {
        state.orgName = localStorage.getItem('orgName')
      }
      return state.orgName
    },
    isAuth (state) {
      if (state.isAuth === '' || state.isAuth === null) {
        state.isAuth = localStorage.getItem('isAuth')
      }
      return state.isAuth
    },
    regType (state) {
      if (state.regType === '' || state.regType === null) {
        state.regType = localStorage.getItem('regType')
      }
      return state.regType
    },
    orgType (state) {
      if (state.orgType === '' || state.orgType === null) {
        state.orgType = localStorage.getItem('orgType')
      }
      return state.orgType
    }
  },
  actions: {
    // 登录
    async getLogin({ commit, state, dispatch }, params) {
      const res = await reqLogin(params)
      if (res.result === 'success') {
        let username = params.KEYDATA.split(',')[0].split('FSZN')[1]
        commit('SET_DATAS', username)
      }
      return Promise.resolve(res)
    },
    // 获取用户信息
    async getUserInfo({ commit, state, dispatch }, params) {
      const res = await GetUserInfo(params)
      console.log(res);
      commit('SET_USERID', res.USER_ID)
      commit('SET_XCXNAME', res.XCX_NAME)
      commit('SET_ORGID', res.ORG_ID)
      commit('SET_ORG_NAME', res.ORG_NAME)
      commit('SET_REG_TYPE', res.REGIST_TYPE)
      if (state.loginType === 'qiye') {
        dispatch('getOrgDetail', { ENTERPRISE_ID: res.ORG_ID, tm: new Date().getTime() })
      }
      return Promise.resolve(res)
    },
    async getUserOutLine({ commit, state, dispatch }, params) {
      return await GetOutLine(params)
    },
    // 获取企业信息
    async getOrgDetail({ commit, state, dispatch }, params) {
      const res = await GetOrgDetail(params)
      console.log(res);
      if (res.pd) {
        commit('SET_IS_AUTH', res.pd.IS_AUTHENTICATION)
        commit('SET_ORG_TYPE', res.pd.ENTERPRISE_TYPE)
      } else {
        commit('SET_IS_AUTH', 'N')
      }
      return res
    },
    // 存储登录的类型(企业、金融机构、政府)
    setLoginType({ commit, state, dispatch }, params) {
      commit('SET_TYPE', params.lType)
    }
  },
  mutations: {
    // 本地存储用户信息
    SET_DATAS (state, res) {
      state.isLogin = true
      localStorage.setItem('isLogin', true)
      localStorage.setItem('userName', res)
      state.isLogin = true
      state.userName = res
    },
    SET_ISUPLOADIMG(state,res) {
      localStorage.setItem('isUploadImg', res)
      state.isUploadImg = res
    },
    SET_USERID (state, res) {
      localStorage.setItem('userId', res)
      state.userId = res
    },
    SET_XCXNAME (state, res) {
      localStorage.setItem('xcxName', res)
      state.xcxName = res
    },
    SET_TYPE (state, res) {
      localStorage.setItem('loginType', res)
      state.loginType = res
    },
    SET_ORGID (state, res) {
      localStorage.setItem('orgId', res)
      state.orgId = res
    },
    SET_ORG_NAME (state, res) {
      localStorage.setItem('orgName', res)
      state.orgName = res
    },
    SET_IS_AUTH (state, res) {
      localStorage.setItem('isAuth', res)
      state.isAuth = res
    },
    SET_REG_TYPE (state, res) {
      localStorage.setItem('regType', res)
      state.regType = res
    },
    SET_ORG_TYPE (state, res) {
      localStorage.setItem('orgType', res)
      state.orgType = res
    },
    // 删除本地用户信息
    loginOut (state) {
      state.isLogin = false
      state.userName = ''
      state.userId = ''
      state.xcxName = ''
      state.loginType = ''
      localStorage.clear()
    }
  }
}

export default loginModule
