import Axios from '../axios'
import BaseUrl from '../base'

// 登录
export function reqLogin(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs + '/admin/check',
    params: data
  })
}
// 是否上传四张图片
export function uploadImg(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs + '/enterprise/searchSwitch',
    params: data
  })
}
// 退出登录
export function logout(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs + '/main/logout_crpt',
    params: data
  })
}

// 获取用户信息
export function GetUserInfo(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/admin/getUserInfo',
    params: data
  })
}

// 获取企业信息
export function GetOrgDetail(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/goEdit',
    params: data
  })
}
//1.6.企业所有附件列表内容
export function list(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprisefiles/list?showCount=-1&currentPage=1',
    params: data
  })
}

//模板下载地址
export function downEnterfile(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/enterprise/downEnterfile',
    params: data
  })
}
export function loginOutY(data) {
  return Axios({
    method: 'get',
    url: BaseUrl.cs +'/main/logout',
    params: data
  })
}
export function goLogoPic(data) {
  return Axios({
    method: 'post',
    url: BaseUrl.cs + '/syslogo/goLogoPic',
    params: data
  })
}
