import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'normalize.css/normalize.css'
import 'echarts/lib/component/tooltip'
import VueUeditorWrap from 'vue-ueditor-wrap';
import * as echarts from 'echarts'
import scroll from "vue-seamless-scroll/src"
const app = createApp(App)
app.echarts = echarts


// const apiUrl = 'http://120.46.160.20:80/crpt'  //服务器地址
const apiUrl = 'https://www.chanrongfuwu.com/crpt'  //服务器地址
// const apiUrl = 'http://localhost:8051/crpt'
// const apiUrl = 'http://114.116.202.28:10000/crpt'


// 上传文件接口
//app.config.globalProperties.$uploadApi = apiUrl + '/enterprisefiles/add' //测试环境
app.config.globalProperties.$uploadApi = apiUrl + '/enterprise/editFileAddr' //生产环境
app.config.globalProperties.$baseUrl = apiUrl
// 销项发票下载模板接口
app.config.globalProperties.$downloadXX = apiUrl + '/jydata_xiaoxiang/downExcel'
// 销项发票上传接口
app.config.globalProperties.$uploadXX = apiUrl + '/jydata_xiaoxiang/readExcel'
// 进项发票下载模板接口
app.config.globalProperties.$downloadJX = apiUrl + '/jydata_jinxiang/downExcel'
// 进项发票上传接口
app.config.globalProperties.$uploadJX = apiUrl + '/jydata_jinxiang/readExcel'
// 个税下载模板接口
app.config.globalProperties.$downloadGS = apiUrl + '/individual/downExcel'
// 个税上传数据接口
app.config.globalProperties.$uploadGS = apiUrl + '/individual/readExcel'
// 能耗下载模板接口
app.config.globalProperties.$downloadNH = apiUrl + '/nenghao_data/downExcel'
// 能耗上传数据接口
app.config.globalProperties.$uploadNH = apiUrl + '/nenghao_data/readExcel'
// 养老保险下载模板接口
app.config.globalProperties.$downloadYL = apiUrl + '/shebaodata_yanglao/downExcel'
// 养老保险上传数据接口
app.config.globalProperties.$uploadYL = apiUrl + '/shebaodata_yanglao/readExcel'
// 医疗、工伤、生育、公积金下载模板接口
app.config.globalProperties.$downloadOT = apiUrl + '/shebaodata_others/downExcel'
// 医疗、工伤、生育、公积金上传数据接口
app.config.globalProperties.$uplaodOT = apiUrl + '/shebaodata_others/readExcel'
//深度报告下载接口
app.config.globalProperties.$downDeepRep = apiUrl + '/enterdepthreport/download'
app.config.globalProperties.$downExcel = apiUrl + '/enterprise/downEnterfile'
//下载数据包
app.config.globalProperties.$downloadDataPackage = apiUrl + '/dataExport/dataExport'
app
  .use(store)
  .use(router)
  .use(Antd)
    .use(VueUeditorWrap)
  .use(scroll)
  .mount("#app")
