<template>
    <div class="carouselWrap">
        <a-carousel :autoplay="true" arrows>
            <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 100px;zIndex: 1">
                <left-circle-outlined />
            </div>
            </template>
            <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 100px;zIndex: 1">
                <right-circle-outlined />
            </div>
            </template>
            <!--  -->
            <div v-for="(item,index) in banner" :key="index"><img :src="item" alt="" @click="target(item)"></div>
<!--            <div><img src="../assets/images/banner.jpg" alt=""></div>-->
<!--            <div><img src="../assets/images/banner.jpg" alt=""></div>-->
        </a-carousel>
    </div>
</template>

<script>
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
    export default {
        name:'Carousel',
        components: {
            LeftCircleOutlined,
            RightCircleOutlined,
        },
        props:['bannerList'],
        data(){
            return{
                banner:this.bannerList
            }
        },
        methods:{
            // 图片点击跳转新网页
            target(item){
                if (item.includes('homeBan7')){
                    window.open('http://lh.casicloud.com/view', '_blank');
                }
            },
        },
        watch:{
            bannerList(val){
                console.log(val,'1111')
                // this.banner = val
            }
        }
    }
</script>

<style lang="less" scoped>
/* For demo */
.carouselWrap{
    width: 1440px;
    margin: 0 auto;
}

.ant-carousel ::v-deep(.slick-slide) {
  overflow: hidden;
  height: 450px;
}
.ant-carousel ::v-deep(.slick-arrow.custom-slick-arrow) {
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel ::v-deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel ::v-deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}
</style>
